/** @jsx jsx */
import React, { ReactNode } from "react"
import { jsx } from "theme-ui"
import {VictoryPie, VictoryLegend, VictoryLabel, VictoryChart, VictoryBar, Bar} from "victory"
import { CopyBlock, atomOneDark } from "react-code-blocks"

type CardWrapperProps = {
  title: string,
  data: any,
  bg: string
}

const card = (title, data) => {
  if (title == "title") {
    return <div>Hinge...Wrapped?</div>
  } else if (title == "total_matches_title") {
    return <div>You have matched with {data.matches} people!</div>
  } else if (title == "match_vs_reject_pie") {
    const matches = parseInt(data.matches)
    const total = parseInt(data.total)
    const rejected = parseInt(data.rejected)
    const unrequited = (total - (matches+rejected)).toString()
    return <div>
      <div sx={{
      position:`absolute`,
      width:`100px`,
      top: `15%`,
      transform: `translate(90%, -50%)`,
      }}>You match with {Math.round(matches/total * 100)}% of the people you've seen</div>
      <div  sx={{
      position:`absolute`,
      transform: `translate(-80%, -50%)`,
      }}>
      <svg
      width={300}
      height={300}
      viewBox="0 0 400 400"
    >
      <VictoryPie
          height={400} width={400}
          standalone={false}
          data={[
            { x: data.rejected + " rejected", y: rejected }, { x: data.matches + " matches", y: matches }, { x: unrequited + " unresponded likes", y: (total - (matches+rejected)) }
          ]}
          innerRadius={68} labelRadius={100}
          style={{ labels: { fontSize: 15, fill: "white" }}}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 15 }}
          x={200} y={200}
          text= "Total Interactions"
        />
    </svg></div>
    </div>
  } else if (title == "chat_bar") {
    const chats = parseInt(data.total) || 0
    const u_ghosted = parseInt(data.u_ghost) || 0
    const they_ghost = parseInt(data.they_ghost) || 0
    return <div>
      <div sx={{
      position:`absolute`,
      width:`100px`,
      top: `15%`,
      transform: `translate(-250%, -50%)`,
      }}>You never sent a message to {Math.round((u_ghosted + they_ghost)/((u_ghosted + they_ghost + chats)) * 100)}% of the people you match with</div>
      <div  sx={{
      position:`absolute`,
      transform: `translate(-30%, -83%)`,
      }}>
      <svg
      width={400}
      height={600}
      viewBox="0 0 400 400"
    ></svg>
      <VictoryChart height={400} width={600}
          domainPadding={{ x: 50, y: [0, 20] }}
          scale={{ x: "time" }}
        >
          <VictoryBar
            dataComponent={
              <Bar />
            }
            data={[
              { x: "1", y: chats },
              { x: "2", y: u_ghosted },
              { x: "3", y: they_ghost }
            ]}
          />
          <VictoryLegend x={80} y={0}
            padding={{ left: 20, right: 60 }}
            title="Legend"
            centerTitle
            orientation="horizontal"
            gutter={30}
            style={{ border: { stroke: "black" }, title: {fontSize: 20 } }}
            data={[
              { name: "1: Chatted" },
              { name: "2: U liked, u ghosted" },
              { name: "3: They liked, u ghosted"}
            ]}
          />
        </VictoryChart>
        </div>
      </div>
  } else if (title == "top_match") {
    return <div sx={{
      width:`250px`}}>Top Match of 2020 #{data.match}, with {data.most_chats} messages sent by you</div>
  } else {
    return <div><div sx={{
      position:`absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      }}><CopyBlock
      customStyle={{
        height: '250px',
        margin: '0px 0.75rem',
        borderRadius: '5px',
        boxShadow: '1px 2px 3px rgba(0,0,0,0.35)',
        fontSize: '0.50rem',
      }}
    text={JSON.stringify(data, null, 2)}
    language="json"
    showLineNumbers={false}
    theme={atomOneDark}
    codeBlock
  /></div><div sx={{
    width:`100px`,
      top: `15%`,
      transform: `translate(-200%, -50%)`,
    }}>Check out your raw data here:</div></div>
  }

}

const CardWrapper = ({ title, data, bg }: CardWrapperProps) => {

  return <div
    sx={{
      height: `100%`,
      width: `100%`,
      boxShadow: `lg`,
      position: `relative`,
      textDecoration: `none`,
      borderRadius: `lg`,
      px: 4,
      py: [4, 5],
      color: `white`,
      background: bg ||  `none`,
      transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
      "&:hover": {
        color: `white !important`,
        transform: `translateY(-5px)`,
        boxShadow: `xl`,
      },
    }}
  >
    <div sx={{
      position:`absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      }}> {card(title, data)} </div>
  </div>
}

export default CardWrapper
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Well that was fun`}</h2>
    <blockquote>
      <p parentName="blockquote">{`What do you think?`}</p>
    </blockquote>
    <p>{`Data is a funny thing, it's fun to see (and as a result, feel seen), and can also be easily misinterpreted. There's a lot of nuances into why people send likes, why people match, and why people may reject (or just never respond) to a like on Hinge. Everyone has their own preferences. Everyone's at a different point in their lives. It's difficult (and impossible!) to understand why other people do the things they do without asking them directly. Just a few things to keep in mind :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
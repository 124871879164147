import React, { useCallback , useMemo } from 'react'
import { jsx } from "theme-ui"
import {useDropzone} from 'react-dropzone'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8%',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

const formatData = (res) => {
    const formatted = [
      {
        id: 0,
        title : "title",
        data : {
          total: res["interactions"]
        }
      },
      {
          id: 3,
          title : "total_matches_title",
          data : {matches: res["matches"]}
      },
      {
        id: 4,
        title : "match_vs_reject_pie",
        data : {
          matches: res["matches"],
          rejected: res["rejected"],
          total: res["interactions"]
        },
      },
      {
        id: 5,
        title : "chat_bar",
        data : {
          total: res["chats"],
          u_ghost: res["u_ghosted"],
          they_ghost: res["they_ghosted"]
        }
      },
      {
        id: 6,
        title : "top_match",
        data: {match: res["top_match"], most_chats: res["most_chats"]}
      },
      {
        id: 2,
        title : "raw_data",
        data : res,
      }
    ]
    return formatted
}

const FileUploader = ({setData}) => {
    const onDrop = useCallback(async acceptedFiles => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
   
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          const raw_data = reader.result
  
          const json_body = JSON.stringify({ "data": raw_data })
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: json_body
          };
  
          fetch('https://emam97.pythonanywhere.com/', requestOptions)
          .then(res => res.json())
          .then((data) => {
            const res = formatData(data["data"])
            setData(res)
          })
          .catch(console.log)
        }
        reader.readAsText(file)
      })
      
    }, [])
  
    const {getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject} = useDropzone({onDrop})
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject,
      isDragAccept
    ]);
  
    return (
      <div
        sx={{
          width: `100%`,
          boxShadow: `lg`,
          position: `relative`,
          textDecoration: `none`,
          borderRadius: `lg`,
          px: 4,
          py: [4, 5],
          color: `gray`,
          background: "linear-gradient(to right, #FFD300 0%, #DE38C8 100%)",
          transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
          "&:hover": {
            color: `black !important`,
            transform: `translateY(-5px)`,
            boxShadow: `xl`,
          },
        }}
      >
        <h1> submit ur date-a here </h1>
        <p>No worries, I don't save any data. I don't need to know everyone's love lives.</p>
         <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop your matches.json file here, or click to select file</p>
        </div>
      </div>
    )
  }

export default FileUploader
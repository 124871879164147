import React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css';

  
const Carousel = ({children}) => {

    const slideArray = children.map(child => (
        <div
            key={child.index}
            sx={{
                position: `relative`
            }}
        ><Slide index={child.index}> {child.component} </Slide></div>
    ))
    return <CarouselProvider
        naturalSlideWidth={500}
        naturalSlideHeight={200}
        totalSlides={slideArray.length}
        >
            <Slider>
                {slideArray}
            </Slider>
            <ButtonBack>Back</ButtonBack>
            <ButtonNext>Next</ButtonNext>
        </CarouselProvider>

}
  export default Carousel

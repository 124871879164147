import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Enjoyed this web app?`}</h2>
    <p>{`Pay that happiness forward and consider donating to `}<a parentName="p" {...{
        "href": "https://charity.gofundme.com/o/en/campaign/ems-angel-alliance-page"
      }}>{`my GoFundMe supporting the Lower East Side Girls' Club`}</a><br parentName="p"></br>{`
`}<em parentName="p">{`(my venmo's also @emeli-marin lol)`}</em><br parentName="p"></br>{`
`}{`Wanna say hi or message me suggestions? Drop me a DM on `}<a parentName="p" {...{
        "href": "https://www.instagram.com/emsan1ty/"
      }}>{`Instagram`}</a>{` or connect on `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/em-marin-435593a0/"
      }}>{`LinkedIn`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      